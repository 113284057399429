import { CSSReset } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
    Box,
    Flex,
    TranslateProvider as SpUiTranslateProvider,
    StripeProvider,
    ThemeProvider
} from 'sp-ui';
import { BalanceProvider } from './components/balance';
import {
    BankAccountOwnershipVerificationDocumentsRequiredBanner,
    IMerchantAccount,
    MerchantAccountCompletionStatus,
    MerchantAccountGet,
    MerchantAccountProvider,
    MerchantAccountRejectedBanner,
    MerchantAccountVerify,
    useMerchantAccount
} from './components/merchant-account';
import { Navigation } from './components/navigation';
import { OnboardingPageHeader, OnboardingPageProvider } from './components/onboarding';
import { PayoutProvider } from './components/payout';
import {
    PayoutAccountDisabledBanner,
    PayoutAccountFutureRequirementsBanner,
    PayoutAccountProvider,
    PayoutAccountType,
    usePayoutAccount
} from './components/payout-account';
import { Permission, PermissionsProvider, usePermissions } from './components/permissions';
import { ToastProvider } from './components/toast';
import Authentication, { useAuthenticationToken } from './components/Authentication';
import Pendo from './components/Pendo';
import { useMessage } from './components/intl';
import OnboardingCompletePage from './pages/onboarding/Complete';
import OnboardingIncompletePage from './pages/onboarding/Incomplete';
import OnboardingPayoutAccountNewBankAccountPage from './pages/onboarding/PayoutAccountNewBankAccount';
import OnboardingPendingPage from './pages/onboarding/Pending';
import OnboardingSettingsEditPage from './pages/onboarding/SettingsEdit';
import OnboardingStartPage from './pages/onboarding/Start';
import DocumentsPage from './pages/Documents';
import ForbiddenPage from './pages/Forbidden';
import IndexPage from './pages/Index';
import MerchantAccountNewPage from './pages/MerchantAccountNew';
import PayoutPage from './pages/Payout';
import SettingsPage from './pages/Settings';
import TransactionPage from './pages/Transaction';
import Tools from './tools/Tools';
import theme from './theme';
import translations from './translations';

const App: React.FC = () => (
    <GlobalProviders>
        <BrowserRouter>
            <Flex direction="column" height="100%">
                <Switch>
                    <Route component={Authentication} exact path="/authenticate" />
                    <Route component={Unbecome} exact path="/auth/login" />
                    <AuthenticatedSwitch>
                        <Route component={IndexPage} exact path="/" />
                        <Route
                            component={MerchantAccountNewPage}
                            exact
                            path="/payment-account/new"
                        />
                        <Route
                            component={MerchantAccountVerify}
                            exact
                            path="/payment-account/:id/verify"
                        />
                        <Route path="/payment-account/:id/onboarding">
                            <MerchantAccountFromRouteProvider>
                                <OnboardingPageProvider>
                                    <OnboardingPageHeader />
                                    <OnboardingRoutes />
                                    <Pendo hasMerchantAccountContext />
                                </OnboardingPageProvider>
                            </MerchantAccountFromRouteProvider>
                        </Route>
                        <Route path="/payment-account/:id">
                            <MerchantAccountFromRouteProvider requireCompleteMerchantAccount>
                                <PayoutAccountProvider>
                                    <PayoutAccountHasBankAccount>
                                        <BankAccountOwnershipVerificationDocumentsRequiredBanner />
                                        <MerchantAccountRejectedBanner />
                                        <PayoutAccountDisabledBanner />
                                        <PayoutAccountFutureRequirementsBanner />
                                        <Navigation />
                                        <Box flex="1" overflow="hidden">
                                            <MerchantAccountRoutes />
                                        </Box>
                                    </PayoutAccountHasBankAccount>
                                </PayoutAccountProvider>
                                <Pendo hasMerchantAccountContext />
                            </MerchantAccountFromRouteProvider>
                        </Route>
                        <Route component={ForbiddenPage} exact path="/forbidden" />
                        <Redirect to="/" />
                    </AuthenticatedSwitch>
                    <Redirect to="/authenticate" />
                </Switch>
            </Flex>
        </BrowserRouter>
    </GlobalProviders>
);

export const AuthenticatedSwitch: React.FC = (props) => {
    const { platformAccountId, platformUserId, token } = useAuthenticationToken();
    const { pathname = '/' } = window.location;
    const isUnauthenticated = !platformAccountId || !platformUserId || !token;
    const [redirectToAuthenticate, setRedirectToAuthenticate] = useState<boolean>(
        isUnauthenticated
    );

    useEffect(() => {
        if (isUnauthenticated) {
            setRedirectToAuthenticate(true);
        }
    }, [isUnauthenticated]);

    if (redirectToAuthenticate) {
        return <Redirect to={`/authenticate?returnTo=${encodeURIComponent(pathname)}`} />;
    }

    return <Switch {...props} />;
};

export const GlobalProviders: React.FC = (props) => {
    const { language: locale } = navigator;
    const messages = translations[locale.toLowerCase()] || translations['en'];
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    return (
        <CookiesProvider>
            <PermissionsProvider>
                <ThemeProvider partialTheme={theme}>
                    <CSSReset />
                    <StripeProvider stripeKey={`${process.env.REACT_APP_STRIPE_API_KEY}`}>
                        <ToastProvider>
                            <IntlProvider locale={locale} messages={messages} timeZone={timeZone}>
                                <TranslateProvider {...props} />
                            </IntlProvider>
                        </ToastProvider>
                    </StripeProvider>
                </ThemeProvider>
            </PermissionsProvider>
        </CookiesProvider>
    );
};

interface IMerchantAccountFromRouteProviderProps {
    requireCompleteMerchantAccount?: boolean;
}

export const MerchantAccountFromRouteProvider: React.FC<IMerchantAccountFromRouteProviderProps> = ({
    requireCompleteMerchantAccount,
    ...props
}) => {
    const [merchantAccount, setMerchantAccount] = useState<IMerchantAccount | null>(null);

    if (requireCompleteMerchantAccount && merchantAccount) {
        const { completionStatus } = merchantAccount;

        if (completionStatus !== MerchantAccountCompletionStatus.Complete) {
            const { id } = merchantAccount;

            return <Redirect to={`/payment-account/${id}/onboarding`} />;
        }
    }

    return (
        <>
            <MerchantAccountGet onGot={setMerchantAccount} />
            {merchantAccount && (
                <MerchantAccountProvider merchantAccount={merchantAccount} {...props} />
            )}
        </>
    );
};

export const MerchantAccountRoutes: React.FC = () => {
    const { hasPermission } = usePermissions();

    return (
        <Switch>
            <Redirect exact from="/payment-account/:id" to="/payment-account/:id/payment" />
            <Route component={Tools} exact path="/payment-account/:id/tools" />
            {hasPermission(Permission.Admin) && (
                <Route
                    component={SettingsPage}
                    exact
                    path="/payment-account/:id/settings/account"
                />
            )}
            {hasPermission(Permission.Admin) && (
                <Route component={SettingsPage} exact path="/payment-account/:id/settings/payout" />
            )}
            {hasPermission(Permission.Admin) && (
                <Redirect
                    from="/payment-account/:id/settings"
                    to="/payment-account/:id/settings/account"
                />
            )}
            <Route>
                <BalanceProvider>
                    <PayoutProvider>
                        <Switch>
                            <Route
                                component={TransactionPage}
                                exact
                                path="/payment-account/:id/payment"
                            />
                            <Route
                                component={PayoutPage}
                                exact
                                path="/payment-account/:id/payout"
                            />
                            <Route
                                component={DocumentsPage}
                                exact
                                path="/payment-account/:id/documents"
                            />
                            <Redirect from="/payment-account/:id" to="/payment-account/:id" />
                        </Switch>
                    </PayoutProvider>
                </BalanceProvider>
            </Route>
        </Switch>
    );
};

export const OnboardingRoutes: React.FC = () => (
    <Switch>
        <Route
            component={OnboardingCompletePage}
            exact
            path="/payment-account/:id/onboarding/complete"
        />
        <Route
            component={() => (
                <PayoutAccountProvider>
                    <OnboardingIncompletePage />
                </PayoutAccountProvider>
            )}
            exact
            path="/payment-account/:id/onboarding/incomplete"
        />
        <Route
            component={() => (
                <PayoutAccountProvider>
                    <OnboardingPayoutAccountNewBankAccountPage />
                </PayoutAccountProvider>
            )}
            exact
            path="/payment-account/:id/onboarding/payout-account/new"
        />
        <Route
            component={OnboardingPendingPage}
            exact
            path="/payment-account/:id/onboarding/pending"
        />
        <Route
            component={OnboardingSettingsEditPage}
            exact
            path="/payment-account/:id/onboarding/settings/edit"
        />
        <Route
            component={() => (
                <PayoutAccountProvider>
                    <OnboardingStartPage />
                </PayoutAccountProvider>
            )}
        />
    </Switch>
);

export const PayoutAccountHasBankAccount: React.FC = ({ children }) => {
    const [hasLoadedPayoutAccounts, setHasLoadedPayoutAccounts] = useState<boolean>(false);
    const { merchantAccount } = useMerchantAccount();
    const { id } = merchantAccount;
    const onboardingPayoutAccountNewPagePath = `/payment-account/${id}/onboarding/payout-account/new`;
    const { payoutAccounts, payoutAccountsLoading } = usePayoutAccount();

    useEffect(() => {
        setHasLoadedPayoutAccounts(false);
    }, [onboardingPayoutAccountNewPagePath]);

    useEffect(() => {
        if (!payoutAccountsLoading) {
            setHasLoadedPayoutAccounts(true);
        }
    }, [payoutAccountsLoading]);

    if (!hasLoadedPayoutAccounts) {
        return null;
    }

    const hasNoBankAccount =
        payoutAccounts.filter(({ type }) => type === PayoutAccountType.BankAccount).length === 0;

    if (hasNoBankAccount) {
        return <Redirect to={onboardingPayoutAccountNewPagePath} />;
    }

    return <>{children}</>;
};

export const TranslateProvider: React.FC = ({ children, ...props }) => {
    const t = useMessage();
    const translate = useCallback((key, tokenData) => t(key, tokenData), [t]);

    return (
        <SpUiTranslateProvider translate={translate} {...props}>
            {children}
        </SpUiTranslateProvider>
    );
};

export const Unbecome: React.FC = () => {
    const { invalidateToken, isDelegated } = useAuthenticationToken();

    useEffect(() => {
        invalidateToken();
    }, [invalidateToken]);

    // If we are an imposter then lets redirect back to admin
    if (isDelegated) {
        const url = process.env.REACT_APP_IMPERSONATE_REDIRECT || '/';

        window.location.replace(url);

        return null;
    }

    // We want this to return to root to avoid redirect loop
    return <Redirect to={`/authenticate?returnTo=${encodeURIComponent('/')}`} />;
};

export default App;
